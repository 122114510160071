export const getErrors = (error: { type: string }, name: string) => {
  if (error?.type === "pattern" && name === "email") return "validate_email";
  if (error?.type === "pattern" && name === "password")
    return "validate_password";
  if (error?.type === "pattern" && name === "confirm_password")
    return "validate_password_equal";
  if (error?.type === "validate" && name === "password")
    return "validate_password";
  if (error?.type === "validate" && name === "confirm_password")
    return "validate_confirm";
  if (error?.type === "validate" && name === "checkDuplicate")
    return "validate_duplicate";
  if (error?.type === "validate" && name === "oldPassword")
    return "validate_oldPassword";

  if (error?.type === "required") return "validate_required";
  if (error?.type === "pattern") return "validate_errors";
  if (error?.type === "validate") return "validate_errors";
  if (name === "checkDuplicate") return "validate_duplicate";

  return "";
};
