import { takeLatest } from "redux-saga/effects";
import { ActionType } from "./types/actionType";
import {
  handleTableRowClicked,
  handleTableActionButtonClicked,
  handleControlToggleSwitched,
  handleControlCreateButtonClicked,
  handleControlImportButtonClicked,
  handleControlSetPriceButtonClicked,
  handleConfirmOrderButtonClicked,
  handleCompleteOrderButtonClicked,
  handleShippingOrderButtonClicked,
} from "./workers";

export function* watcher() {
  yield takeLatest(ActionType.TABLE_ROW_CLICKED, handleTableRowClicked);
  yield takeLatest(
    ActionType.TABLE_ACTION_BUTTON_CLICKED,
    handleTableActionButtonClicked
  );
  yield takeLatest(
    ActionType.CONTROL_TOGGLE_SWITCHED,
    handleControlToggleSwitched
  );
  yield takeLatest(
    ActionType.CONTROL_CREATE_BUTTON_CLICKED,
    handleControlCreateButtonClicked
  );
  yield takeLatest(
    ActionType.CONTROL_IMPORT_BUTTON_CLICKED,
    handleControlImportButtonClicked
  );
  yield takeLatest(
    ActionType.CONFIRM_ORDER_BUTTON_CLICKED,
    handleConfirmOrderButtonClicked
  );
  yield takeLatest(
    ActionType.COMPLETE_ORDER_BUTTON_CLICKED,
    handleCompleteOrderButtonClicked
  );
  yield takeLatest(
    ActionType.SHIPPING_ORDER_BUTTON_CLICKED,
    handleShippingOrderButtonClicked
  );
  yield takeLatest(
    ActionType.CONTROL_SET_PRICE_BUTTON_CLICKED,
    handleControlSetPriceButtonClicked
  );
}
