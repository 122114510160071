import { SLICE_NAME } from "../../model/types/types";

export const ActionType = {
  ASSIGN_ORDER: `${SLICE_NAME}/assign_order`,
  CONFIRM_ORDER: `${SLICE_NAME}/confirm_order`,
  COMPLETE_ORDER: `${SLICE_NAME}/complete_order`,
  MANAGE_ORDER: `${SLICE_NAME}/manage_order`,
  RETRIEVE_ORDER: `${SLICE_NAME}/retrieve_order`,
  RETRIEVE_MANY_ORDERS: `${SLICE_NAME}/retrieve_many_orders`,
  MANAGE_SHIPPING_ORDER: `${SLICE_NAME}/manage_shipping_order`,
} as const;
