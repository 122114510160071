import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@mui/material';
import { getErrors } from '../../utils/getErrors';
import { IconWarning, Text, Wrapper } from './styles';

const Error = (props) => {
    const { name, color, error, label } = props;
    const { t } = useTranslation();

    const errorMessage = error?.message
        ? t(error?.message)
        : t(getErrors(error, name), { errorKey: label });

    return (
      <Collapse in={!!errorMessage}>
        <Wrapper>
          <IconWarning />
          <Text color={color} data-at={`${name}__error`}>{errorMessage}</Text>
        </Wrapper>
      </Collapse>
    );
};

Error.defaultProps = {
    label: '',
    name: '',
    error: null,
    color: 'color_299',
    colorIcon: 'color_299',
};

export default Error;
