import { start } from "repl";

export const API_URL = {
  domain: process.env.REACT_APP_API_URL,
  auth: {
    me: "/api/auth/me",
    login: "/api/auth/login",
    logout: "/api/auth/logout",
    refreshSession: "/api/auth/refresh_session",
    //ToDo temporary
    conditions: "/api/user_conditions",
  },
  users: {
    getMany: "/api/users/get_many",
    createOne: "/api/users/create",
    getOne: "/api/users",
    updateOne: "/api/users",
    deleteOne: "/api/users",
    updateAvatar: "/api/users/update_avatar",
    deleteAvatar: "/api/users/delete_avatar",
  },
  vendors: {
    getMany: "/api/vendors/get_many",
    createOne: "/api/vendors/create",
    getOne: "/api/vendors",
    updateOne: "/api/vendors",
    deleteOne: "/api/vendors",
  },
  inventory: {
    samples: {
      importFile: "/api/inventory/samples/import_file",
      getFileTemplate: "/api/inventory/samples/get_file_template",
      createOne: "/api/inventory/samples/create",
      getOne: "/api/inventory/samples",
      updateOne: "/api/inventory/samples",
      deleteOne: "/api/inventory/samples",
      getMany: "/api/inventory/samples/get_many",
      customRequest: "/api/inventory/samples/custom_request",
      setPrice: "/api/inventory/samples/set_batch_price",
    },
    invoices: {
      createOne: "/api/inventory/invoices/create",
      getOne: "/api/inventory/invoices",
      updateOne: "/api/inventory/invoices",
      deleteOne: "/api/inventory/invoices",
      getMany: "/api/inventory/invoices/get_many",
    },
  },
  storageFacilities: {
    createOne: "/api/storage_facilities",
    getOne: "/api/storage_facilities",
    updateOne: "/api/storage_facilities",
    deleteOne: "/api/storage_facilities",
    getMany: "/api/storage_facilities/get_many",
  },
  clientCompanies: {
    createOne: "/api/client_companies/create",
    getOne: "/api/client_companies",
    updateOne: "/api/client_companies",
    deleteOne: "/api/client_companies",
    getMany: "/api/client_companies/get_many",
    shippingLocations: {
      getMany: "/api/client_companies/shipping_locations/get_many",
      updateOne: "/api/client_companies/shipping_locations",
      createOne: "/api/client_companies/shipping_locations",
      deleteOne: "/api/client_companies/shipping_locations",
    },
  },
  researchAreas: {
    getAll: "/api/research_areas/get_all",
    items: {
      getMany: "/api/research_areas/items/get_many",
    },
    biomarkers: {
      getMany: {
        start: "/api/research_areas/items/",
        end: "/get_loinc_biomarkers",
      },
    },
  },
  panels: {
    getAll: "/api/panels",
  },
  clientOrders: {
    getOne: "/api/client_orders",
    getMany: "/api/client_orders/get_many",
    createCart: "/api/client_orders/create_cart",
    addItemToCart: {
      start: "/api/client_orders/",
      end: "/add_item_to_cart",
    },
    removeItemFromCart: {
      start: "/api/client_orders/",
      end: "/remove_item_from_cart",
    },
    placeOrder: {
      start: "/api/client_orders/",
      end: "/place",
    },
    placeCustomRequest: "/api/client_orders/place_by_custom_request",
    manageOrder: {
      start: "/api/client_orders/",
      end: "/manage",
    },
    createOrderShipment: {
      start: "/api/client_orders/",
      end: "/shipments",
    },
    assignOrder: {
      start: "/api/client_orders/",
      end: "/assign",
    },
  },
  loincBiomarker: {
    getMany: "/api/loinc_biomarkers/get_many",
    getOne: "/api/loinc_biomarkers",
    updateOne: "/api/loinc_biomarkers",
    loincRanges: {
      createOne: "/api/loinc_ranges",
      deleteOne: "/api/loinc_ranges",
    },
  },
  contactUs: {
    getMany: "/api/contact_us/get_chat_list",
    getOne: "/api/contact_us/get_chat_messages",
    deleteOne: "/api/contact_us",
    updateOne: "/api/contact_us/create_reply",
    createMessageAuthorized: "/api/contact_us/create_message_authorized",
  },
};

// export const SELECTED_THEME = "selected_theme";

// export const SELECTED_SCALE_FONT_SIZE = "selected_scale_font_size";

// export const SELECTED_FONT_FAMILY = "selected_font_family";

// export const SELECTED_LETTER_SPACING = "selected_letter_spacing";

// export const REMINDERS_CONTENT_DISPLAYING = "reminders_content_displaying";

// export const IS_SHOWN_COOKIES = "is_shown_cookies";

// export const IS_SHOWN_GREETING_MODAL = "is_shown_greeting_modal";
