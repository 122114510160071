import { ILoincBiomarker } from '@/shared/types';
import { ScaleTypeVerified } from '@/shared/types/enums/ScaleTypeVerified.enum';

export const modifyBiomarkerData = (item: ILoincBiomarker): ILoincBiomarker => {
  const scaleType = item.scaleType as keyof typeof ScaleTypeVerified;
  return {
    ...item,
    verified: item?.verified ? 'Active' : 'Inactive',
    scaleType: scaleType in ScaleTypeVerified ? ScaleTypeVerified[scaleType] : item.scaleType
  };
};
