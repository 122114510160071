import styled from 'styled-components';

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
  grid-gap: 0 ${({ theme }) => theme.coefficient * 11}px;
  margin: ${({ theme, mt }) => (mt ? theme.coefficient * mt : 0)}px 0 0 0;
`;

export const IconWarning = styled.span`
  height: ${({ theme }) => theme.coefficient * 24}px;
  width: ${({ theme }) => theme.coefficient * 24}px;
  background: url("${({ theme }) => theme.images.warning}") no-repeat center;
`;

export const Text = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.value_35)};
  line-height: 1.2;
`;
