import { GlobalStyles as MuiGlobalStyles, useTheme } from "@mui/material";
import React from "react";

function GlobalStyles() {
  const theme = useTheme();

  return (
    <>
      <MuiGlobalStyles
        styles={{
          html: {
            fontSize: `${theme.coefficient * 10}px`,
          },
          "input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0px 1000px transparent inset !important",
            WebkitTextFillColor: "inherit !important",
            caretColor: "inherit !important",
          },
          "input:-webkit-autofill:hover": {
            WebkitBoxShadow: "0 0 0px 1000px transparent inset !important",
            WebkitTextFillColor: "inherit !important",
            caretColor: "inherit !important",
          },
          "input:-webkit-autofill:focus": {
            WebkitBoxShadow: "0 0 0px 1000px transparent inset !important",
            WebkitTextFillColor: "inherit !important",
            caretColor: "inherit !important",
          },
          "input:-webkit-autofill:active": {
            WebkitBoxShadow: "0 0 0px 1000px transparent inset !important",
            WebkitTextFillColor: "inherit !important",
            caretColor: "inherit !important",
          },
          ".MuiBarLabel-root": {
            fontSize: "1.5rem !important",
            fill: "white !important",
          },
        }}
      />
    </>
  );
}

export { GlobalStyles };
