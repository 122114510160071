import { call, put, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";
import { UUID } from "crypto";

import { commonSlice } from "@/entities/common";
import { usersSagaActions } from "@/entities/users";

import { selectCurrentEntity } from "@/widgets/table";

import { EntityType, SideBlockMode, ToggleActionType } from "@/shared/types";

import { selectSettings } from "../model/selectors";
import { asideSlice } from "../model/slice";
import { IAsideSettings } from "../model/types/interfaces";
import { CreatePayload, SavePayload, ToggleAsidePayload } from "./types/types";
import { samplesSagaActions } from "@/entities/samples";
import { vendorsSagaActions } from "@/entities/vendors";
import { clientCompaniesSagaActions } from "@/entities/client-companies";
import { chatsSagaActions } from "@/entities/chats";
import { ordersSagaActions } from '@/entities/client-orders';
import { biomarkersSagaActions } from '@/entities/biomarkers';

export function* handleCreateButtonClicked(
  action: PayloadAction<CreatePayload>
) {
  const { formData } = action.payload;
  const { createUser } = usersSagaActions;
  const { createSample } = samplesSagaActions;
  const { createVendor } = vendorsSagaActions;
  const { createCompany } = clientCompaniesSagaActions;
  const { createLoincRange } = biomarkersSagaActions;
  const { setLoadingAside } = asideSlice;

  const currentEntity: EntityType = yield select(selectCurrentEntity);

  yield put(setLoadingAside({ isLoading: true }));

  switch (currentEntity) {
    case EntityType.USER:
      yield put(createUser({ formData }));
      break;
    case EntityType.SAMPLE:
      yield put(createSample({ formData }));
      break;
    case EntityType.VENDOR:
      yield put(createVendor({ formData }));
      break;
    case EntityType.COMPANY:
      yield put(createCompany({ formData }));
      break;
    case EntityType.LOINC_BIOMARKER:
      yield put(createLoincRange({ formData }));
      break;
  }
}

export function* handleSaveButtonClicked(action: PayloadAction<SavePayload>) {
  const { id, formData } = action.payload;
  const { setLoadingAside } = asideSlice;
  const { updateUser } = usersSagaActions;
  const { updateSample } = samplesSagaActions;
  const { updateVendor } = vendorsSagaActions;
  const { updateCompany } = clientCompaniesSagaActions;
  const { updateChat } = chatsSagaActions;
  const { manageOrder } = ordersSagaActions;
  const { deleteLoincRanges, updateLoinc } = biomarkersSagaActions;

  const currentEntity: EntityType = yield select(selectCurrentEntity);

  yield put(setLoadingAside({ isLoading: true }));

  switch (currentEntity) {
    case EntityType.USER:
      yield put(updateUser({ userId: id as UUID, formData }));
      break;
    case EntityType.SAMPLE:
      yield put(updateSample({ sampleId: id as number, formData }));
      break;
    case EntityType.VENDOR:
      yield put(updateVendor({ vendorId: id as number, formData }));
      break;
    case EntityType.COMPANY:
      yield put(updateCompany({ clientCompanyId: id as number, formData }));
      break;
    case EntityType.CHAT:
      yield put(updateChat({ chatId: id as number, formData }));
      break;
    case EntityType.ORDER:
      yield put(manageOrder({ orderId: id as number, formData }));
      break;
    case EntityType.LOINC_BIOMARKER:
      if (id) {
        yield put(updateLoinc({ loincBiomarkerId: id, formData }))
      }
      yield put(deleteLoincRanges({ formData }));
      break;
  }
}

export function* handleToggleSideBlock(
  action: PayloadAction<ToggleAsidePayload>
) {
  const { status } = action.payload;

  switch (status) {
    case ToggleActionType.OPEN:
      yield call(openSideBlock, action);
      break;
    case ToggleActionType.CLOSE:
      yield call(closeSideBlock);
      break;
  }
}

function* closeSideBlock() {
  const { setAsideSettings, setLoadingAside } = asideSlice;
  const { setActiveEntity } = commonSlice;

  const asideSettings: IAsideSettings = yield select(selectSettings);

  yield put(setLoadingAside({ isLoading: false }));
  yield put(
    setAsideSettings({
      settings: {
        mode: SideBlockMode.CLOSED,
        isOpen: false,
        t_title: asideSettings.t_title,
        t_submitBtn: asideSettings.t_submitBtn,
      },
    })
  );
  yield put(setActiveEntity({ activeEntity: null }));
}

function* openSideBlock(action: PayloadAction<ToggleAsidePayload>) {
  const { mode, t_title, t_submitBtn } = action.payload;
  const { setAsideSettings } = asideSlice;

  yield put(
    setAsideSettings({
      settings: {
        mode: mode || SideBlockMode.CLOSED,
        isOpen: true,
        t_title: t_title || t("create_user"),
        t_submitBtn: t_submitBtn || t("create"),
      },
    })
  );
}
