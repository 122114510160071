export enum DialogMode {
  EDIT = "edit",
  INFO = "view",
  CLOSED = "closed",
  IMPORT = "import",
  DELETE = "delete",
  CONFIRM = "confirm",
  SET_PRICE = "set_price",
  ORDER_FORM = "order_form",
  PLACE_ORDER = "place_order",
  PRODUCT_INFO = "product_info",
  CONFIRM_ORDER = "confirm_order",
  COMPLETE_ORDER = "complete_order",
  SHIPPING_ORDER = "shipping_order",
  CUSTOM_REQUEST_FORM = "custom_request_form",
  ADD_SHIPPING_LOCATION = "add_shipping_location",
  DELETE_SHIPPING_LOCATION = "delete_shipping_location",
}
