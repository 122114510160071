import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select } from "redux-saga/effects";
import { selectActions } from "@/features";
import { IGetManyParams } from "@/shared/types";
import { selectData, selectParams } from "@/features";
import { ISelectData } from "../model/types";
import {
  GetManyDataResponse,
  InfinitySelectOpenPayload,
} from "@/features/infinity-select/api/types/types";
import { getManyLoincBiomarkers, getManySamples } from "@/entities/samples/api/senders";
import { InfinitySelectDataType } from "@/shared/types/enums/InfinitySelectDataType.enum";

export function* handleInfinitySelectOpen(
  action: PayloadAction<InfinitySelectOpenPayload>
) {
  const { setData, setIsLoading } = selectActions;
  const { searchValue, dataType } = action.payload;

  const params: IGetManyParams = yield select(selectParams);

  const bodyParams = {
    ...params,
    active: true,
    ...(searchValue && { search: { general: searchValue } }),
  };

  try {
    yield put(setIsLoading({ isLoading: true }));

    const { items, count } = yield call(retrieveData, dataType, bodyParams);

    yield put(setData({ data: { items, count } }));

    yield put(setIsLoading({ isLoading: false }));
  } catch (error) {
    yield put(setIsLoading({ isLoading: false }));
    console.error("handleInfinitySelectOpen ERROR!!!", error);
  }
}

export function* handleInfinityLoadMore<T>(
  action: PayloadAction<{ dataType: string }>
) {
  const { setData, setParams, setIsLoading } = selectActions;
  const params: IGetManyParams = yield select(selectParams);
  const data: ISelectData<T> = yield select(selectData);

  const bodyParams = {
    ...params,
    active: true,
    skip: (params?.skip ?? 0) + (params?.limit ?? 10),
  };

  yield put(setIsLoading({ isLoading: true }));
  const { items, count } = yield call(
    retrieveData,
    action.payload?.dataType,
    bodyParams
  );
  yield put(setData({ data: { items: [...data.items, ...items], count } }));
  yield put(setParams({ params: bodyParams }));
  yield put(setIsLoading({ isLoading: false }));
}

export function* handleInfinitySelectClose() {
  const { setData, setParams } = selectActions;
  yield put(setData({ data: { items: [], count: 0 } }));
  yield put(setParams({ params: { skip: 0, limit: 10 } }));
}

function* retrieveData<T>(
  dataType: string,
  bodyParams: any
): Generator<any, GetManyDataResponse<T> | undefined, any> {
  switch (dataType) {
    case InfinitySelectDataType.LoincBiomarker:
      return yield call(getManyLoincBiomarkers, bodyParams);
    case InfinitySelectDataType.samples:
      return yield call(getManySamples, bodyParams);
    default:
      return undefined;
  }
}
