import { SLICE_NAME } from "../../model/types/types";

export const ActionType = {
  GET_SAMPLE: `${SLICE_NAME}/get_sample`,
  CREATE_SAMPLE: `${SLICE_NAME}/create_sample`,
  UPDATE_SAMPLE: `${SLICE_NAME}/update_sample`,
  DELETE_SAMPLE: `${SLICE_NAME}/delete_sample`,
  GET_MANY_SAMPLES: `${SLICE_NAME}/get_many_samples`,
  CUSTOM_REQUEST: `${SLICE_NAME}/custom_request`,
  PLACE_CUSTOM_REQUEST: `${SLICE_NAME}/place_custom_request`,
  IMPORT_SAMPLES: `${SLICE_NAME}/import_samples`,
  SET_PRICE: `${SLICE_NAME}/set_price`,
} as const;
