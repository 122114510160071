import { API_URL } from "@/shared/api/apiUrl";
import { sendRequest } from "@/shared/api/requestSender";
import { HttpMethod, ILoincBiomarker, ISample } from '@/shared/types';
import { IGetManyBiomarkersParams } from "../model/types/interfaces";

export const sendGetManyBiomarkersRequest = (body: IGetManyBiomarkersParams) =>
  sendRequest({
    path: API_URL.loincBiomarker.getMany,
    method: HttpMethod.POST,
    data: body,
  });

export const sendCreateLoincRangeRequest = (body: any) =>
  sendRequest({
    path: API_URL.loincBiomarker.loincRanges.createOne,
    method: HttpMethod.POST,
    data: body,
  });

export const sendDeleteLoincRangesRequest = (loinc_range_id: string) =>
  sendRequest({
    path: `${API_URL.loincBiomarker.loincRanges.deleteOne}/${loinc_range_id}`,
    method: HttpMethod.DELETE,
  });

export const sendGetBiomarkerRequest = (biomarkerId: number) =>
  sendRequest({
    path: `${API_URL.loincBiomarker.getOne}/${biomarkerId}`,
    method: HttpMethod.GET,
  });

export const sendUpdateLoincRequest = (loincBiomarkerId: string | number, body: Partial<ILoincBiomarker> | unknown) =>
  sendRequest({
    path: `${API_URL.loincBiomarker.updateOne}/${loincBiomarkerId}`,
    method: HttpMethod.PATCH,
    data: body
  });
