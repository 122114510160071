import { put, select, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  commonSlice,
  selectActiveTab,
  selectNavigationTabs,
} from "@/entities/common";

import { StorageKeys } from "@/shared/types/enums/StorageKeys.enum";
import {
  AlertStatusMessage, DialogMode,
  ICart,
  ISample,
  ITabItem,
  ToggleActionType,
  UserRole,
} from '@/shared/types';
import {
  setItemToLS,
  removeItemFromLS,
} from "@/shared/utils/localStorageHelpers";

import { getCurrentUser, getNavigationTabsByUserRole } from "./helpers";
import { UserPayload, ActiveTabValuePayload, CardPayload } from "./types/types";
import { selectUserCart } from '@/entities/common';
import {
  sendCreateCartRequest,
  sendAddItemToCartRequest,
  sendRemoveItemFromCartRequest, sendPlaceOrderRequest,
} from './senders';
import { dialogSagaActions } from "@/widgets/dialog";
import { alertsSagaActions } from '@/entities/alerts';

export function* handleUserLoggedIn(action: PayloadAction<UserPayload>) {
  const { setCurrentUser, setNavigationTabs, setActiveTab } = commonSlice;
  const currentUser = getCurrentUser(action.payload.user);
  const navigationTabs: Array<ITabItem> = yield call(
    getNavigationTabsByUserRole,
    currentUser.role
  );
  const activeTab: ITabItem = yield select(selectActiveTab);
  yield put(setCurrentUser({ currentUser }));
  yield put(setNavigationTabs({ navigationTabs }));
  if (!activeTab) {
    yield call(
      setItemToLS,
      StorageKeys.ACTIVE_TAB,
      JSON.stringify(navigationTabs[0])
    );
    yield put(setActiveTab({ activeTab: navigationTabs[0] }));
  }
}

export function* handleUserLoggedOut() {
  const { setActiveTab, setNavigationTabs, setCurrentUser, setLoading } = commonSlice;
  yield put(setActiveTab({ activeTab: null }));
  yield put(setCurrentUser({ currentUser: { role: UserRole.UNAUTHORIZED } }));
  yield put(setNavigationTabs({ navigationTabs: [] }));
  yield put(setLoading({ isLoading: false }));
  yield call(removeItemFromLS, StorageKeys.ACTIVE_TAB);
}

export function* handleUpdateActiveTab(
  action: PayloadAction<ActiveTabValuePayload>
) {
  const { setActiveTab } = commonSlice;
  const { value } = action.payload;
  const navigationTabs: Array<ITabItem> = yield select(selectNavigationTabs);
  const activeTab = navigationTabs[value];

  yield put(setActiveTab({ activeTab }));
  yield call(setItemToLS, StorageKeys.ACTIVE_TAB, JSON.stringify(activeTab));
}

export type CreateCartResponse = {
  data: {
    data: ICart;
  };
};

export type AddItemToCartResponse = {
  data: {
    data: ICart;
  };
};

export type RemoveItemFromCartResponse = {
  data: {
    data: ICart;
  };
};

export type placeOrderResponse = {
  data: {
    data: ICart;
  };
};

// export const modifyCartData = (cart: ICart) => {
//   const samples = cart.samples;

//   const products = samples.map((sample: ISample) => {
//     return { ...sample, hasWatched: `${item.firstName} ${item.lastName}` };
//   });
// };

export function* handleAddSampleToCart(action: PayloadAction<CardPayload>) {
  const { setUserCart } = commonSlice;
  let cart: ICart | null = yield select(selectUserCart);

  if (!cart) {
    const response: CreateCartResponse = yield call(sendCreateCartRequest);
    cart = response.data.data;
  }

  const response2: AddItemToCartResponse = yield call(
    sendAddItemToCartRequest,
    cart.id as string,
    action.payload
  );
  cart = response2.data.data;

  yield put(setUserCart({ cart }));
}

export function* handleRemoveProductFromCart(
  action: PayloadAction<CardPayload>
) {
  const { setUserCart } = commonSlice;
  const { toggleDialog } = dialogSagaActions;

  let cart: ICart | null = yield select(selectUserCart);

  if (!cart) {
    return;
  }

  const response: RemoveItemFromCartResponse = yield call(
    sendRemoveItemFromCartRequest,
    cart.id as string,
    action.payload
  );
  cart = response.data.data;

  yield put(setUserCart({ cart }));
  yield put(toggleDialog({ status: ToggleActionType.CLOSE }));
}

export function* handlePlaceOrder(
  action: PayloadAction<CardPayload>
) {
  const { setUserCart, setActiveEntity } = commonSlice;
  const { toggleDialog } = dialogSagaActions;
  const { updateAlert } = alertsSagaActions;

  let cart: ICart | null = yield select(selectUserCart);

  if (!cart) {
    return;
  }

  const {data: { data }} = yield call(
    sendPlaceOrderRequest,
    cart.id as string,
    action.payload
  );

  yield put(setActiveEntity({ activeEntity: data }));
  yield put(setUserCart({ cart: null }));
  yield put(toggleDialog({ status: ToggleActionType.CLOSE }));

  yield put(
    toggleDialog({
      status: ToggleActionType.OPEN,
      mode: DialogMode.CONFIRM,
    })
  );
}
