import styled from "@emotion/styled";
import { IconButton, Box } from "@mui/material";

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: ${({ theme }) => theme.coefficient * 400}px;
  min-height: ${({ theme }) => theme.coefficient * 200}px;
  grid-gap: ${({ theme }) => theme.coefficient * 20}px 0;
  padding: ${({ theme }) => theme.coefficient * 24}px;
  background-color: ${({ theme }) => theme.colors.value_01};
  z-index: 0;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomIconButton = styled(IconButton)`
  &:hover {
    background-color: ${({ theme }) => theme.rgb.value_02};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.value_27};
  overflow: auto;
`;

export const FooterContainer = styled.div`
  display: flex;
  height: ${({ theme }) => theme.coefficient * 40}px;
  grid-gap: 0 ${({ theme }) => theme.coefficient * 16}px;
`;
