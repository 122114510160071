import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";
import { ActionType } from "./types/actionType";
import { AssignOrderPayload, GetOrderPayload } from '@/entities/client-orders/api/types/types';

const manageOrder = createAction<any>(ActionType.MANAGE_ORDER);
const manageShippingOrder = createAction<any>(ActionType.MANAGE_SHIPPING_ORDER);
const assingOrder = createAction<AssignOrderPayload>(ActionType.ASSIGN_ORDER);
const confirmOrder = createAction<AssignOrderPayload>(ActionType.CONFIRM_ORDER);
const completeOrder = createAction<AssignOrderPayload>(ActionType.COMPLETE_ORDER);
const retrieveOrder = createAction<GetOrderPayload>(ActionType.RETRIEVE_ORDER);
const retrieveManyOrders = createAction(ActionType.RETRIEVE_MANY_ORDERS);

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      assingOrder,
      manageOrder,
      confirmOrder,
      completeOrder,
      retrieveOrder,
      retrieveManyOrders,
      manageShippingOrder,
    },
    dispatch
  );
};

export const sagaActions = {
  assingOrder,
  manageOrder,
  confirmOrder,
  completeOrder,
  retrieveOrder,
  retrieveManyOrders,
  manageShippingOrder,
};
