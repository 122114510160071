import { SLICE_NAME } from "../../model/types/types";

export const ActionType = {
  TABLE_ROW_CLICKED: `${SLICE_NAME}/row_clicked`,
  TABLE_ACTION_BUTTON_CLICKED: `${SLICE_NAME}/action_button_clicked`,
  CONTROL_TOGGLE_SWITCHED: `${SLICE_NAME}/control_toggle_switched`,
  CONTROL_CREATE_BUTTON_CLICKED: `${SLICE_NAME}/control_create_button_clicked`,
  CONTROL_IMPORT_BUTTON_CLICKED: `${SLICE_NAME}/control_import_button_clicked`,
  CONTROL_SET_PRICE_BUTTON_CLICKED: `${SLICE_NAME}/control_set_price_button_clicked`,
  CONFIRM_ORDER_BUTTON_CLICKED: `${SLICE_NAME}/confirm_order_button_clicked`,
  COMPLETE_ORDER_BUTTON_CLICKED: `${SLICE_NAME}/complete_order_button_clicked`,
  SHIPPING_ORDER_BUTTON_CLICKED: `${SLICE_NAME}/shipping_order_button_clicked`,
} as const;
