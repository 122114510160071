import { createSlice } from "@reduxjs/toolkit";
import { InitialStateType, SLICE_NAME, CaseReducers } from "./types/types";

const INITIAL_STATE: InitialStateType = {
  data: { items: [], count: 0 },
  params: { active: true },
  // activeBiomarker: null,
};

const slice = createSlice<
  InitialStateType,
  CaseReducers<InitialStateType>,
  typeof SLICE_NAME,
  never
>({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setBiomarkers: (draft, { payload }) => {
      draft.data = payload.biomarkers;
    },
    setParams: (draft, { payload }) => {
      draft.params = { ...draft.params, ...payload.params };
    },
    // setActiveBiomarker: (draft, { payload }) => {
    //   draft.activeBiomarker = payload.activeBiomarker;
    // },
  },
});

export const biomarkersSlice = slice.actions;
export const biomarkersReducer = slice.reducer;
