import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { UUID } from 'crypto';
import { DialogMode, EntityType, ToggleActionType } from '@/shared/types';
import { commonSagaActions, commonSlice } from '@/entities/common';
import { usersSagaActions } from '@/entities/users';
import { samplesSagaActions } from '@/entities/samples';
import { vendorsSagaActions } from '@/entities/vendors';
import { selectCurrentEntity } from '@/widgets/table';
import { IDialogSettings } from '../model/types/interfaces';
import { dialogSlice, selectDialogSettings } from '..';
import { SubmitDialogPayload, ToggleDialogPayload } from './types/types';
import { chatsSagaActions } from '@/entities/chats';
import { clientCompaniesSagaActions } from '@/entities/client-companies';
import { ordersSagaActions } from '@/entities/client-orders';

export function* handleSubmitButtonClicked(
  action: PayloadAction<SubmitDialogPayload>
) {
  const {
    id,
    status,
    filters,
    formData,
    currentVendor,
    storageLocation,
    storageFacilityId,
    shippingLocationId,
    purchaseOrderNumber,
    currentShippingOptions,
  } = action.payload;
  const { deleteUser } = usersSagaActions;
  const { deleteSample, placeCustomRequest } = samplesSagaActions;
  const { deleteVendor } = vendorsSagaActions;
  const { deleteCompany, createShippingLocation, deleteShippingLocation } = clientCompaniesSagaActions;
  const { deleteChat } = chatsSagaActions;
  const { importSamples, setPrice } = samplesSagaActions;
  const { removeCartProduct, placeOrder } = commonSagaActions;
  const { manageShippingOrder } = ordersSagaActions;

  const currentEntity: EntityType = yield select(selectCurrentEntity);
  const dialogSettings: IDialogSettings = yield select(selectDialogSettings);

  switch (currentEntity) {
    case EntityType.USER:
      if (dialogSettings.mode === DialogMode.ADD_SHIPPING_LOCATION) {
        yield put(createShippingLocation({ formData: filters }));
        break;
      }
      if (dialogSettings.mode === DialogMode.DELETE_SHIPPING_LOCATION) {
        yield put(deleteShippingLocation({ shippingLocationId: id as number }));
        break;
      }
      yield put(deleteUser({ userId: id as UUID }));
      break;
    case EntityType.SAMPLE:
      if (dialogSettings.mode === DialogMode.IMPORT) {
        yield put(importSamples({ formData, currentVendor, storageFacilityId, storageLocation }))
        break;
      }
      if (dialogSettings.mode === DialogMode.SET_PRICE) {
        yield put(setPrice({ formData }))
        break;
      }
      yield put(deleteSample({ sampleId: id as number }));
      break;
      case EntityType.ORDER:
      if (dialogSettings.mode === DialogMode.SHIPPING_ORDER) {
        yield put(manageShippingOrder({ orderId: id as number, formData, status }));
        break;
      }
      break;
    case EntityType.VENDOR:
      yield put(deleteVendor({ vendorId: id as number }));
      break;
    case EntityType.COMPANY:
      yield put(deleteCompany({ clientCompanyId: id as number }));
      break;
    case EntityType.CHAT:
      yield put(deleteChat({ chatId: id as number }));
      break;
    case EntityType.CART:
      if (dialogSettings.mode === DialogMode.DELETE) {
        yield put(removeCartProduct({ sampleId: id as number }));
      }
      if (dialogSettings.mode === DialogMode.PLACE_ORDER) {
        yield put(placeOrder({ sampleId: id as number, shippingLocationId: shippingLocationId as number }));
      }
      break;
    case EntityType.LOINC_BIOMARKER:
      if (dialogSettings.mode === DialogMode.CUSTOM_REQUEST_FORM) {
        yield put(placeCustomRequest({
          loincCode: id as number,
          shippingLocationId: shippingLocationId as number,
          purchaseOrderNumber: purchaseOrderNumber,
          shipmentMethod: currentShippingOptions,
          filters: filters,
        }))
      }
      break;
  }
}

export function* handleToggleDialog(
  action: PayloadAction<ToggleDialogPayload>
) {
  const { status } = action.payload;

  switch (status) {
    case ToggleActionType.OPEN:
      yield call(openDialog, action);
      break;
    case ToggleActionType.CLOSE:
      yield call(closeDialog, action);
      break;
  }
}

function* closeDialog(action: PayloadAction<ToggleDialogPayload>) {
  const { isntEntityClear } = action.payload;
  const { setDialogSettings, setLoadingDialog } = dialogSlice;
  const { setActiveEntity } = commonSlice;

  const dialogSettings: IDialogSettings = yield select(selectDialogSettings);

  yield put(setLoadingDialog({ isLoading: false }));
  yield put(
    setDialogSettings({
      settings: {
        mode: DialogMode.CLOSED,
        isOpen: false,
        t_title: dialogSettings.t_title,
        t_submitBtn: dialogSettings.t_submitBtn,
      },
    })
  );
  if (!isntEntityClear) {
    yield put(setActiveEntity({ activeEntity: null }));
  }
}

function* openDialog(action: PayloadAction<ToggleDialogPayload>) {
  const { mode, t_title, t_submitBtn } = action.payload;
  const { setDialogSettings } = dialogSlice;

  yield put(
    setDialogSettings({
      settings: {
        mode: mode || DialogMode.CLOSED,
        isOpen: true,
        t_title: t_title || t("create_user"),
        t_submitBtn: t_submitBtn || t("create"),
      },
    })
  );
}
