import { call } from "redux-saga/effects";
import { UUID } from "crypto";

import { API_URL } from "@/shared/api/apiUrl";
import { sendRequest } from "@/shared/api/requestSender";
import { HttpMethod, IInvoice } from "@/shared/types";

import { IGetManyInvoicesParams } from "../model/types/interfaces";
import {
  GetInvoiceResponse,
  CreateInvoiceResponse,
  DeleteInvoiceResponse,
  UpdateInvoiceResponse,
  GetManyInvoicesResponse,
} from "./types/types";

export const sendCreateInvoiceRequest = (body: Partial<IInvoice> | unknown) =>
  sendRequest({
    path: API_URL.inventory.invoices.createOne,
    method: HttpMethod.POST,
    data: body,
  });

const sendGetInvoiceRequest = (invoiceId: UUID) =>
  sendRequest({
    path: `${API_URL.inventory.invoices.getOne}/${invoiceId}`,
    method: HttpMethod.GET,
  });

const sendUpdateInvoiceRequest = (
  invoiceId: UUID,
  body: Partial<IInvoice> | unknown
) =>
  sendRequest({
    path: `${API_URL.inventory.invoices.updateOne}/${invoiceId}`,
    method: HttpMethod.PATCH,
    data: body,
  });

const sendDeleteInvoiceRequest = (invoiceId: UUID) =>
  sendRequest({
    path: `${API_URL.inventory.invoices.deleteOne}/${invoiceId}`,
    method: HttpMethod.DELETE,
  });

const sendGetManyInvoicesRequest = (body: IGetManyInvoicesParams) =>
  sendRequest({
    path: API_URL.inventory.invoices.getMany,
    method: HttpMethod.POST,
    data: body,
  });

export function* createInvoice(dto: Partial<IInvoice>) {
  try {
    const response: CreateInvoiceResponse = yield call(
      sendCreateInvoiceRequest,
      dto
    );
    return response.data;
  } catch (err) {
    // ToDo handle error properly
    console.log("CREATE INVOICE ERROR", err);
  }
}

export function* getInvoice(invoiceId: UUID) {
  try {
    const response: GetInvoiceResponse = yield call(
      sendGetInvoiceRequest,
      invoiceId
    );
    return response.data.data;
  } catch (err) {
    // ToDo handle error properly
    console.log("GET INVOICE ERROR", err);
  }
}

export function* updateInvoice(invoiceId: UUID, dto: Partial<IInvoice>) {
  try {
    const response: UpdateInvoiceResponse = yield call(
      sendUpdateInvoiceRequest,
      invoiceId,
      dto
    );
    return response.data.data;
  } catch (err) {
    // ToDo handle error properly
    console.log("UPDATE INVOICE ERROR", err);
  }
}

export function* deleteInvoice(invoiceId: UUID) {
  try {
    const response: DeleteInvoiceResponse = yield call(
      sendDeleteInvoiceRequest,
      invoiceId
    );
    return response.data.data;
  } catch (err) {
    // ToDo handle error properly
    console.error("DELETE INVOICE ERROR", err);
  }
}

export function* getManyInvoices(body: IGetManyInvoicesParams) {
  try {
    const response: GetManyInvoicesResponse = yield call(
      sendGetManyInvoicesRequest,
      body
    );
    return response.data.data;
  } catch (error) {
    // ToDo error action isError setting
    console.error("RETRIEVE INVOICES GET MANY ERROR", error);
  }
}
