import React from "react";
import { useSelector } from "react-redux";
import { useController, useFormContext } from 'react-hook-form';

import { ILoincBiomarker } from "@/shared/types";
import CustomAutocomplete from "@/shared/components/inputs/customAutocomplete/customAutocomplete";
import { useSelectActions, selectIsLoading } from "@/features/";

interface InfinitySelectProps {
  name: string;
  rules?: any;
  label: string;
  options?: Array<ILoincBiomarker>;
  optionKey?: string;
  placeholder: string;
}

export const InfinitySelect: React.FC<InfinitySelectProps> = ({
     name,
     label,
     rules,
     options,
     optionKey,
     placeholder,
     }) => {
  const { control } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const isLoading = useSelector(selectIsLoading);

  const { infinitySelectClose, infinitySelectOpen, infinitySelectLoadMore } =
    useSelectActions();

  const onChangeCallback = (searchValue: string) => {
    infinitySelectOpen({ searchValue, dataType: name });
  };

  const onCloseCallback = () => {
    infinitySelectClose();
  };

  const onScrollCallback = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    const isNeedLazyLoad = scrollTop + clientHeight >= scrollHeight - 1;

    if (isNeedLazyLoad) {
      infinitySelectLoadMore({ dataType: name });
    }
  };

  return (
    <CustomAutocomplete
      name={name}
      error={error}
      label={label}
      optionKey={optionKey}
      placeholder={placeholder}
      control={control}
      options={options}
      isLoading={isLoading}
      onScrollCallback={onScrollCallback}
      onChangeCallback={onChangeCallback}
      onCloseCallback={onCloseCallback}
    />
  );
};
