import { SLICE_NAME } from "../../model/types/types";

export const ActionType = {
  UPDATE_LOINC: `${SLICE_NAME}/update_loinc`,
  RETRIEVE_BIOMARKER: `${SLICE_NAME}/retrieve_biomarker`,
  RETRIEVE_MANY_BIOMARKERS: `${SLICE_NAME}/retrieve_many_biomarkers`,
  CREATE_LOINC_RANGE: `${SLICE_NAME}/create_loinc_range`,
  DELETE_LOINC_RANGES: `${SLICE_NAME}/delete_loinc_ranges`,
  // TABLE_ROW_CLICKED: `${SLICE_NAME}/table_row_clicked`,
  // SHOW_ALL_TOGGLE_SWITCHED: `${SLICE_NAME}/show_all_toggle_switched`,
  // VIEW_ROW_BUTTON_CLICKED: `${SLICE_NAME}/view_row_button_clicked`,
  // REQUEST_ROW_BUTTON_CLICKED: `${SLICE_NAME}/request_row_button_clicked`,
} as const;
