import { useDispatch } from "react-redux";
import { bindActionCreators, createAction } from "@reduxjs/toolkit";
import { ActionType } from "./types/actionType";
import { GetBiomarkerPayload, GetManyBiomarkersPayload, UpdateLoincPayload } from "./types/types";

const retrieveManyBiomarkers = createAction<
  GetManyBiomarkersPayload | undefined
>(ActionType.RETRIEVE_MANY_BIOMARKERS);

const retrieveBiomarker = createAction<GetBiomarkerPayload>(
  ActionType.RETRIEVE_BIOMARKER
);

const updateLoinc = createAction<UpdateLoincPayload>(
  ActionType.UPDATE_LOINC
);

const createLoincRange = createAction<any>(
  ActionType.CREATE_LOINC_RANGE
);

const deleteLoincRanges = createAction<any>(
  ActionType.DELETE_LOINC_RANGES
);

// const tableRowClicked = createAction<ClickedRowItemPayload>(
//   ActionType.TABLE_ROW_CLICKED
// );

// const showAllToggleSwitched = createAction<ShowAllTogglePayload>(
//   ActionType.SHOW_ALL_TOGGLE_SWITCHED
// );

// const viewRowButtonClicked = createAction<ClickedRowItemPayload>(
//   ActionType.VIEW_ROW_BUTTON_CLICKED
// );

// const requestRowButtonClicked = createAction<ClickedRowItemPayload>(
//   ActionType.REQUEST_ROW_BUTTON_CLICKED
// );

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(
    {
      updateLoinc,
      createLoincRange,
      deleteLoincRanges,
      retrieveBiomarker,
      retrieveManyBiomarkers,
    },
    dispatch
  );
};

export const sagaActions = {
  updateLoinc,
  createLoincRange,
  deleteLoincRanges,
  retrieveBiomarker,
  retrieveManyBiomarkers,
};
