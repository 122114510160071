import { call } from "redux-saga/effects";
import { UUID } from "crypto";

import { API_URL } from "@/shared/api/apiUrl";
import { sendRequest } from "@/shared/api/requestSender";
import { HttpMethod, ISample } from "@/shared/types";

import { IGetManySamplesParams } from "../model/types/interfaces";
import { GetManyLoincBiomarkersResponse, GetManySamplesResponse } from './types/types';

export const sendCreateSampleRequest = (body: Partial<ISample> | unknown) =>
  sendRequest({
    path: API_URL.inventory.samples.createOne,
    method: HttpMethod.POST,
    data: body,
  });

export const sendCustomRequest = (body: Partial<ISample> | unknown) =>
  sendRequest({
    path: API_URL.inventory.samples.customRequest,
    method: HttpMethod.POST,
    data: body,
  });

export const sendImportSamplesRequest = (body: Partial<ISample> | unknown, params: any) =>
  sendRequest({
    path: API_URL.inventory.samples.importFile,
    method: HttpMethod.POST,
    data: body,
    params: params,
  });

export const sendSetPriceRequest = (body: Partial<ISample> | unknown) =>
  sendRequest({
    path: API_URL.inventory.samples.setPrice,
    method: HttpMethod.POST,
    data: body,
  });

export const sendPlaceCustomRequest = (body: Partial<ISample> | unknown) =>
  sendRequest({
    path: API_URL.clientOrders.placeCustomRequest,
    method: HttpMethod.POST,
    data: body,
  });

export const sendGetSampleRequest = (sampleId: number) =>
  sendRequest({
    path: `${API_URL.inventory.samples.getOne}/${sampleId}`,
    method: HttpMethod.GET,
  });

export const sendUpdateSampleRequest = (
  sampleId: number,
  body: Partial<ISample> | unknown
) =>
  sendRequest({
    path: `${API_URL.inventory.samples.updateOne}/${sampleId}`,
    method: HttpMethod.PATCH,
    data: body,
  });

export const sendDeleteSampleRequest = (sampleId: number) =>
  sendRequest({
    path: `${API_URL.inventory.samples.deleteOne}/${sampleId}`,
    method: HttpMethod.DELETE,
  });

export const sendGetManySamplesRequest = (body: IGetManySamplesParams) =>
  sendRequest({
    path: API_URL.inventory.samples.getMany,
    method: HttpMethod.POST,
    data: body,
  });

const sendGetManyLoincRequest = (body: any) =>
  sendRequest({
    path: API_URL.loincBiomarker.getMany,
    method: HttpMethod.POST,
    data: body,
  });

export function* getManyLoincBiomarkers(params: IGetManySamplesParams) {
  try {
    const response: GetManyLoincBiomarkersResponse = yield call(
      sendGetManyLoincRequest,
      params
    );

    const modifiedLoinc = response.data.data.items.map((loinc) => ({
      ...loinc,
      fullName: `${loinc.id} ${loinc.name}`,
    }))

    return {items: modifiedLoinc, count: response.data.data.count};
  } catch (error) {
    // ToDo error action isError setting
    console.error("GET MANY LOINC ERROR", error);
  }
}

export function* getManySamples(params: IGetManySamplesParams) {
  try {
    const response: GetManySamplesResponse = yield call(
      sendGetManySamplesRequest,
      params
    );

    const modifiedSamples = response.data.data.items.map((sample) => ({
      ...sample,
      name: sample.loincBiomarker.name,
    }))


    return {items: modifiedSamples, count: response.data.data.count};
  } catch (error) {
    // ToDo error action isError setting
    console.error("GET MANY SAMPLES ERROR", error);
  }
}
